import { FC } from 'react'

import { OnePakLogo } from 'components/OnePakLogo'
import styles from './Footer.module.scss'

export const Footer: FC = () => {
  return (
    <div className={styles.Footer}>
      <div>
        <OnePakLogo />
        <p>Copyright &copy; 2006-{new Date().getFullYear()} OnePak Inc. All rights reserved.</p>
      </div>
      <div>
        <a target="_blank" rel="noopener noreferrer" href="https://www.returncenter.com/about/">
          About Us
        </a>
        |
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://returncenter.com/onepak/terms_of_use.php"
        >
          Terms & Conditions
        </a>
        |
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.returncenter.com/privacy-policy/"
        >
          Privacy Policy
        </a>
        |
        <a target="_blank" rel="noopener noreferrer" href="https://www.returncenter.com/contact/">
          Contact Us
        </a>
      </div>
    </div>
  )
}
