/** If any of these routes are modified, the unbrandedRoutes array must also be
 * changed. This is for comparing the url and checking
 * if the url parameter is a brand or path
 */

export const GUEST_ROUTES = {
  assets: '/guest/order/assets',
  boxes: '/guest/order/boxes',
  checkoutSummary: '/guest/order/checkout_summary',
  feedback: '/guest/order/feedback',
  orderConfirmation: '/guest/order/order_confirmation',
  outOfServiceArea: '/guest/out_of_service_area',
  outOfServiceAreaConfirmation: '/guest/out_of_service_area_confirmation',
  pallets: '/guest/order/pallets',
  payment: '/guest/order/payment',
  pickupLocation: '/guest/order/pickup_location',
  price: '/guest/order/price',
  requestedPickup: '/guest/order/requested_pickup',
}

export const UNAUTHORIZED_ROUTES = {
  alternativeLogin: '/login',
  createAccount: '/create_account',
  forgotPassword: '/email_password_reset',
  forgotPasswordSuccess: '/email_password_reset_success',
  guest: '/guest',
  impersonate: (id?: string): string => `/impersonate/${id ? encodeURIComponent(id) : ':token'}`,
  login: '/sign_in',
  passwordExpired: '/password_expired',
  redirect: (id?: string): string => `/r/${id ? encodeURIComponent(id) : ':uniqueId'}`,
  resetPassword: '/forgot_password',
  resetPasswordSuccess: '/reset_password_success',
}

export const routesForApp = [
  '/account',
  '/create_account',
  '/dashboard',
  '/destination_routings',
  '/destinations',
  '/dispatch_search',
  '/draft_details',
  '/email_password_reset',
  '/email_password_reset_success',
  '/forgot_password',
  '/guest',
  '/impersonate',
  '/login',
  '/order',
  '/order_details',
  '/password_expired',
  '/program',
  '/programs',
  '/r',
  '/report_queue',
  '/report_schedule',
  '/report_submission',
  '/reports',
  '/reset_password_success',
  '/sign_in',
  '/sign_out',
  '/tools',
  '/uploads',
  '/user_admin',
  '/views',
]
